//
// Modal
//

.modal-rounded {
  border-radius: $modal-content-border-radius !important;
}

.custom-datepicker {
  width: 150px;
}

@media (max-width: 420px) {
  .transactionId-smallScreen {
    font-size: 0.8rem !important; 
   
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(8px); /* Adjust the blur amount as needed */
  transition: filter 0.3s ease; /* Add a smooth transition effect */
  z-index: 1040; /* Ensure the overlay is above other elements */
}

.blur {
  filter: blur(0);
}

.modal-backdrop {
  backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
  transition: backdrop-filter 0.3s ease; /* Add a smooth transition effect */
}