// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

#root {
  display: contents;
}

.css-13cymwt-control,
.css-t3ipsp-control:hover,
.css-t3ipsp-control:active,
.css-t3ipsp-control:focus {
  padding: 0.8rem 0.5rem !important;
  background-color: #f9f9f9 !important;
  border-radius: 0.625rem !important;
  font-size: 1.15rem !important;
  border-color: #f9f9f9 !important;
}

.bg-override .css-13cymwt-control {
  border-color: #dbdfe9 !important;
  padding: 0.5rem !important;
}

.bg-overrides .css-13cymwt-control {
  border-color: #dbdfe9 !important;
  background-color: #ffffff;
  padding: 0.5rem !important;
}

.input-preview__src {
  display: none;
}

.input-preview {
  border-radius: 4px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  transition: ease-in-out 750ms;
  box-shadow: #8080804f 5px 5px 4px 0px;
  border: 2px solid white;
}

.input-preview-after::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(50%);
  // content: 'Choose a image...';
  // background-color: #f9f9f9;
  font-weight: bolder;
  font-style: italic;
  font-size: 1em;
}

.has-image::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.5);
  content: ' ';
  transition: ease-in-out 750ms;
}

.has-image::after {
  content: 'Choose another file...';
  color: white;
}
.dashboard-list {
  height: 60vh !important;
  overflow: scroll !important;
}
.relative {
  position: relative;
}
.sticky {
  top: 0;
  position: sticky;
  background: white !important;
  z-index: 9;
}
.react-slideshow-container .default-nav {
  background-color: transparent !important;
}
.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.password-toggle-icon {
  float: right;
  margin-top: -32px;
  margin-right: 11px;
  cursor: pointer;
}

.password-toggle-icon-auth {
  float: right;
  margin-top: -32px;
  margin-right: 35px;
  cursor: pointer;
}

.pending {
  color: #fbb626;
}

.exportOptionsContainer {
  display: flex;
  z-index: 5;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  top: 65px;
  right: 170px;
}

/* Adjusted styles for screens between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .exportOptionsContainer {
    right: 170px;
  }
}

/* Adjusted styles for screens below 768px */
@media (max-width: 767px) {
  .exportOptionsContainer {
    right: 55px;
  }
}

.filterContainer {
  display: flex;
  z-index: 5;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  top: 65px;
  right: 50px;
}

/* Adjusted styles for screens below 768px */
@media (max-width: 768px) {
  .filterContainer {
    right: 55px;
  }
}
